.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1000;
  padding: 10px 20px 0 20px;
}

.header-content {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-color);
  padding: 15px 12px 15px 20px;
  border-radius: 10px 10px 0 0;
  transition: box-shadow 0.3s ease;
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--field-color);
  transition: width 0.1s ease-out;
  z-index: -1;
}

.header.with-shadow .header-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.logo {
  height: 30px;
}