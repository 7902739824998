.join {
  padding: 60px 9% 0 9%;
}

.join-content {
  padding: 60px 20px 30px 20px;
  text-align: center;
}

.join h2 {
  font-size: 3.5rem;
  font-weight: 900;
  margin-bottom: 30px;
  font-family: var(--font-headers);
}

#getWaitlistContainer {
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  justify-content: center;

  div.gw-p-7 {
    padding: 10px !important;
  }

  .gw-mt-4 {
    margin-top: 8px;
  }

  div.gw-w-full span.gw-text-sm {
    display: none;
  }

  div {
    max-width: 700px !important;
    font-family: var(--font-body);

    div.gw-flex.gw-flex-col.gw-rounded-md.gw-p-7.gw-w-full {
      flex-wrap: wrap;
      flex-direction: row;

      &>div {
        width: 100%;
        box-sizing: border-box;
      }

      @media (min-width: 740px) {
        gap: 10px;

        &>div:first-child,
        &>div:nth-child(2) {
          width: 49%;

          .gw-pt-1 {
            padding: 0;
          }
        }
      }
    }
  }


  button#primaryCTA {
    background: var(--accent-color) url('../assets/button-bg.svg') no-repeat center right;
    padding-right: 80px;
    text-transform: uppercase !important;
    font-weight: bold !important;
    height: 38px;
    font-family: var(--font-monospace);
    font-size: 0.7rem;
  }

  input {
    flex: 1;
    padding: 12px 20px;
    background-color: var(--field-color);
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    outline: none;
    color: var(--field-text-color);
    height: 38px;

    &:focus {
      border-color: var(--accent-color);
    }
  }
}