@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Youth';
  src: url('./assets/Youth-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'AeonikMono';
  src: url('./assets/AeonikMono-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--font-color);
}

body {
  margin: 0;
  font-family: var(--font-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

:root {
  --accent-color: #9FE870;
  --accent-color-light: #7FDF60;

  --border-color: #ebebf2;

  --field-color: #EEF3F0;
  --field-text-color: #435;

  --font-color: #333;
  --font-color-light: #8E98A3;
  --font-color-lightest: #C4C4C4;

  --font-body: 'Aeonik', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-headers: 'Youth', var(--font-body);
  --font-monospace: 'AeonikMono', var(--font-body);
}

.section-number {
  color: var(--font-color-light);
  font-size: 0.8rem;
  font-weight: bold;
  font-family: var(--font-monospace);

  &:before {
    width: 10px;
    height: 10px;
    background-color: var(--accent-color);
    content: '';
    display: inline-block;
    margin-right: 6px;
  }
}

h2 {
  text-transform: uppercase;
  font-family: var(--font-headers);
}

.copyright {
  text-align: center;
  padding: 20px;
  color: var(--font-color-lightest);
  font-size: 0.8rem;
}

button {
  background-color: var(--accent-color);
  padding: 12px 30px;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
  text-transform: uppercase;
  white-space: nowrap;
  font-family: var(--font-monospace);
  font-size: 0.7rem;

  &:hover {
    background-color: var(--accent-color-light) !important;
  }
}

@media (max-width: 800px) {
  .footer img {
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .join-content {
    padding: 10px 20px 20px 20px !important;
  }

  .join h2#join {
    font-size: 2rem;
  }

  h2 {
    text-align: center;
    font-size: 2rem !important;
  }

  h1.banner-text {
    font-size: 3rem !important;
  }

  .footer {
    background-image: url(assets/footer-sm.png);
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;

    img {
      display: none;
    }
  }
}

@media (max-width: 420px) {
  div.header-content {
    padding: 15px 10px;
  }

  button {
    padding: 12px 5px;
    font-size: 0.6rem;
    height: 34px;
  }

  div.join {
    padding: 20px 0;

    .section-number {
      margin-left: 20px;
    }
  }

  #getWaitlistContainer button#primaryCTA {
    padding-right: 50px !important;
    height: 34px !important;
    font-size: 0.6rem !important;
  }

  h1.banner-text {
    font-size: 2.5rem !important;
    margin-top: 160px !important;
  }

  div.banner {
    background-size: 550px auto !important;
    padding: 20px 10px;
  }

  .footer {
    height: 400px;
  }
}

@media (max-width: 320px) {
  button.waiting-list-button {
    display: none;
  }

  #getWaitlistContainer button#primaryCTA {
    padding-left: 5px !important;
  }

  div.header-content {
    justify-content: center;
  }

  div.banner {
    background-size: 480px auto !important;
    padding: 10px 4px;
  }

  h1.banner-text {
    font-size: 2.5rem !important;
  }

  .footer {
    height: 300px;
  }
}