.banner {
  position: relative;
  padding: 60px 20px;
  text-align: center;
}

.banner-image {
  max-width: 90%;
  height: auto;
}

h1.banner-text {
  font-size: 5rem;
  font-weight: 900;
  line-height: 1.2;
  margin-top: 40px;
  font-family: var(--font-headers);
  text-transform: uppercase;
  line-height: 0.9;
  color: #000;
  margin-top: -70px;
}

@media (max-width: 1000px) {
  h1.banner-text {
    margin-top: -60px;
  }
}

@media (max-width: 900px) {
  .banner {
    background-image: url('../assets/banner.png');
    background-size: 700px auto;
    background-position: top 20px center;
    background-repeat: no-repeat;
  }

  .banner-image {
    display: none;
  }

  h1.banner-text {
    margin-top: 180px;
    font-size: 4rem;
  }
}