.app {
  min-height: 100vh;
  background-color: #fff;
  padding: 20px;
}

.content-container {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  overflow: auto;
}