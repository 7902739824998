.vision {
  border: 1px solid var(--border-color);
  border-width: 1px 0;
}

.vision-content {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 40px;
}

.vision-text {
  padding: 60px 5% 60px 15%;
}

.vision-text h2 {
  font-size: 2.5rem;
  font-weight: 900;
  margin: 10px 0 20px;
  font-family: var(--font-headers);
}

.vision-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.vision-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-left: 1px solid var(--border-color);
  padding: 60px 0;
}

.vision-image img {
  max-width: 90%;
  height: auto;
}

@media (max-width: 700px) {
  .vision-content {
    grid-template-columns: 1fr;
  }

  .vision-text {
    padding: 60px 20px 10px 20px;
  }

  .vision-image {
    display: none;
  }
}